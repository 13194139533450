<template>
  <v-app-bar
    app
    clipped-left
    scroll-off-screen
    color="#305947"
  >
    <v-app-bar-nav-icon
      dark
      @click.stop="() => $store.commit('toggleSidebar')"
    >
    </v-app-bar-nav-icon>

    <v-toolbar-title>
      <router-link :to="{ name: 'Home' }" style="cursor: pointer;">
        <v-img src="@/assets/pictures/logo-new.jpg" height="50px" width="100%" contain position="left" class="mt-2"/>
      </router-link>
    </v-toolbar-title>

    <v-spacer />
    <v-toolbar-items>

      <!-- Settings menu -->
      <v-btn text icon :to="{name: 'SettingsPanel'}" class="mx-2">
        <v-icon color="secondary">far fa-cogs</v-icon>
      </v-btn>

      <!-- Profile menu -->
      <v-menu
          v-model="showProfileMenu"
          transition="scale-transition"
          origin="center center"
          max-width="300px"
          offset-y>

        <template v-slot:activator="{ on }">
          <div style="cursor: pointer;height: 40px; margin-top: 15px" v-on="on" >
            <v-img
                contain
                height="36"
                width="40"
                v-if="$store.getters['profile/getImg']"
                alt="Profile picture"
                :src="$store.getters['profile/getImg']" />
            <Avatar v-else :username="`${$session.get('person.firstname')} ${$session.get('person.name')}`" :size="40"/>
          </div>
        </template>

        <v-card>
          <v-card-text>
            <v-row>

              <!-- Image -->
              <v-col cols="12" class="text-center">
                <v-img v-if="$store.getters['profile/getImg']" alt="Image" :src="$store.getters['profile/getImg']" contain max-height="50"/>
                <Avatar v-else :username="`${$session.get('person.firstname')} ${$session.get('person.name')}`" :size="50" class="mx-auto"/>
              </v-col>

              <!-- Name -->
              <v-col cols="12" class="text-center py-0">
                <p class="py-0 font-size-20">{{$session.get('person.firstname')}} {{$session.get('person.name')}}</p>
              </v-col>

              <!-- Email -->
              <v-col cols="12" class="text-center py-0">
                <p class="py-0 font-size-14">{{$session.get('email')}}</p>
              </v-col>

              <v-col cols="12" class="pt-0">
                <v-divider class="pt-0" />
              </v-col>

              <v-col cols="12" class="py-0">
                <v-list class="py-0">

                  <!-- Profile -->
                  <v-list-item :to="{name: 'Profile'}" class="py-0" style="text-decoration: none !important; color: inherit !important;">
                    <v-list-item-avatar><v-icon>far fa-user</v-icon></v-list-item-avatar>
                    <v-list-item-title >{{$t('common.topbar.profileMenu.btnProfile')}}</v-list-item-title>
                  </v-list-item>

                  <!-- Logout -->
                  <v-list-item @click="logout" class="py-0">
                    <v-list-item-avatar><v-icon>far fa-sign-out-alt</v-icon></v-list-item-avatar>
                    <v-list-item-title>{{$t('common.topbar.profileMenu.btnLogout')}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>

    </v-toolbar-items>

  </v-app-bar>
</template>

<script>
import Avatar from 'vue-avatar'

export default {

  name: "Topbar",

  components: {
    Avatar
  },

  data() {
    return {
      showProfileMenu: false
    }
  },

  methods: {

    logout() {
      this.$store.dispatch("logout");
      }
    }
  }
</script>

<style scoped>

</style>